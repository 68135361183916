import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import { useIntl } from "gatsby-plugin-intl";
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Input from 'common/src/components/Input';
import CheckBox from 'common/src/components/Checkbox/index';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import LoginModalWrapper from './loginModal.style';
import 'rc-tabs/assets/index.css';
import LogoImage from 'common/src/assets/image/app/tablet.svg';
import LoginImage from 'common/src/assets/image/app/touchlesser.svg';
import stickLogo from 'common/src/assets/image/app/stick.svg';

const LoginModal = ({
  row,
  col,
  btnStyle,
  logoStyle,
  titleStyle,
  contentWrapper,
  outlineBtnStyle,
  descriptionStyle,
  googleButtonStyle,
  register,
}) => {
  const intl = useIntl();
  const LoginButtonGroup = () => (
    <Fragment>
      <Button className="default" title="LOGIN" {...btnStyle} />
      <Button
        title="Forget Password"
        variant="textButton"
        {...outlineBtnStyle}
      />
    </Fragment>
  );
  const [myValue, setMyValue] = useState(null);
  return (
    <LoginModalWrapper>
      <Box className="row" {...row}>
        <Box className="col imageCol" {...col}>
          <Image className="patternImage" src={LoginImage} alt="Login Banner" />
        </Box>
        <Box className="col tabCol" {...col}>
          <Box {...contentWrapper}>
            <Tabs
              renderTabBar={() => <ScrollableInkTabBar />}
              renderTabContent={() => <TabContent />}
            >
              <div>
                <Heading content={intl.formatMessage({ id: "contact_modal_subtitle" })} {...titleStyle} />
                <Text
                  content={intl.formatMessage({ id: "contact_modal_text" })}
                  {...descriptionStyle}
                />
                <form
                  id="email-form"
                  name="email-form"
                  action="https://formspree.io/touchlessr@mosano.eu"
                  method="POST"
                >
                  <Input isMaterial label={intl.formatMessage({ id: "contact_modal_name" })} required inputType="text" inputName="name"/>
                  <Input isMaterial label={intl.formatMessage({ id: "contact_modal_phone" })} required inputType="phone" inputName="phone"/>
                  <Input
                    isMaterial
                    required
                    inputType="email"
                    label={intl.formatMessage({ id: "contact_modal_email" })}
                    iconPosition="left"
                    aria-label="email"
                    inputName="_replyto"
                    value={myValue}
                    onChangeText={(e) => setMyValue(e.target.value)}
                  />
                  <div>
                    <Fragment>
                      <Button type="submit" value="Send" className="default" title={intl.formatMessage({ id: "contact_modal_submit" })} {...btnStyle} />
                    </Fragment>
                  </div>
                </form>
              </div>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </LoginModalWrapper>
  );
};

// LoginModal style props
LoginModal.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  descriptionStyle: PropTypes.object,
  googleButtonStyle: PropTypes.object,
  register: PropTypes.bool, 
};

// LoginModal default style
LoginModal.defaultProps = {
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Team member col default style
  col: {
    width: [1, 1 / 2],
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
    ml: '15px',
  },
  // Title default style
  titleStyle: {
    fontSize: ['22px', '30px', '40px'],
    fontWeight: '400',
    color: '#20201D',
    letterSpacing: '-0.025em',
    mt: '35px',
    mb: '10px',
  },
  // Description default style
  descriptionStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '-0.025em',
    mb: '23px',
    ml: '1px',
  },
  // Content wrapper style
  contentWrapper: {
    pt: ['32px', '56px'],
    pl: ['17px', '32px', '38px', '40px', '56px'],
    pr: '32px',
    pb: ['32px', '56px'],
  },
  // Default button style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  // Outline button outline style
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgb(26, 115, 232)',
  },
  // Google button style
  googleButtonStyle: {
    bg: '#ffffff',
    color: '#343D48',
  },
  register: false,
};

export default LoginModal;
